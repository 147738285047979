<template>
	<v-flex
		xs12 sm4 ma-0 pa-0
		:class="{'mt-3': $vuetify.breakpoint.xs}"
	>
	<packages
		:packages="details.packages"
		:loadingPackages="loadingPackages"
		:onRemove="onRemove"
	/>
	<available-packages
		:onAdd="onAdd"
		:details="details"
		ref="available_packages"
	/>
	</v-flex>
</template>
<script>
import Packages from './Packages.vue';
import AvailablePackages from './AvailablePackages.vue';

export default {
	name: 'PackagesAndAvailablePackages',
	components: {Packages, AvailablePackages},
	props: ['details', 'loadingPackages', 'onAdd', 'onRemove'],
	methods: {
		updateAvailablePackages() {
			this.$refs.available_packages.updateAvailablePackages();
		}
	}
}
</script>
