<template>
	<div fluid>
          <v-card class="my-3" v-if="this.details.status !== 'Inactive'">
            <v-subheader>{{ t('Available packages') }}</v-subheader>
            <v-data-table
              :headers="availableHeaders"
              :items="aviablePackages"
              items-per-page="5"
              class="elevation-1"
              :loading="loadingAviablePackages"
              :search="search"
              :rows-per-page-text="t('Rows per page')"
              :rows-per-page-items="[
                10,
                25,
                50,
                { text: $translate.locale['All'], value: -1 }
              ]"
            >
              <template slot="items" slot-scope="props">
                <td class="text-xs-left no-wrap">
                  {{ props.item.header }}
                  <v-tooltip bottom v-if="props.item.delayed">
                    <template v-slot:activator="{ on }">
                      <v-icon small v-on="on" color="warning">add_alarm</v-icon>
                    </template>
                    <span
                      >{{
                        t('This package will be added in the next transaction.')
                      }}
                    </span>
                  </v-tooltip>
                </td>
                <!-- <td class="text-xs-left no-wrap">{{ props.item.tariff + $store.getters.currency}}</td> -->
                <td class="text-xs-left no-wrap">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        :to="`/packages/${props.item.pk}/details/`"
                        color="info"
                        ripple
                        icon
                        small
                        dark
                      >
                        <v-icon small>info</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('Detail') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="addPackage(props.item.pk)"
                        color="success"
                        ripple
                        icon
                        small
                        dark
                      >
                        <v-icon small>add</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('Add now') }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="!props.item.delayed">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="addPackageLater(props.item.pk)"
                        color="success darken-1"
                        ripple
                        icon
                        small
                        dark
                      >
                        <v-icon small>add_alarm</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('Add later') }}</span>
                  </v-tooltip>
                </td>
              </template>
              <template slot="pageText" slot-scope="item">
                {{ t('Elements') }} {{ item.pageStart }} - {{ item.pageStop }},
                {{ t('total') }}: {{ item.itemsLength }}
              </template>
              <template slot="no-data">
                <v-subheader>{{ t('No data available') }}</v-subheader>
              </template>
            </v-data-table>
          </v-card>
	</div>
</template>
<script>
import axios from 'axios';

export default {
	name: 'AvailableCards',
	props: ['details', 'onAdd'],
	data() {
		return {
		  search: '',
		  loadingAviablePackages: true,
		  aviablePackages: [],
		  availableHeaders: [
			{
			  text: this.$translate.locale['Header'],
			  align: 'left',
			  value: 'header'
			},
			{
			  text: this.$translate.locale['Action'],
			  value: 'action',
			  sortable: false
			}
		  ],
		}
	},
	methods: {
		updateAvailablePackages() {
		  this.loadingAviablePackages = true;
		  axios
			.get(`/api/packages-filter/${this.$route.params.id}/`)
			.then((response) => {
			  if (response.status === 200) {
				this.aviablePackages = response.data;
				this.loadingAviablePackages = false;
			  }
			})
			.catch((error) => {
			  this.text = 'Connection error';
			  this.snackbar = true;
			});
		},
		addPackageLater(pk) {
		  axios
			.post(`/api/cards/delayed-add/package/${this.$route.params.id}/`, {
			  package: pk
			})
			.then((response) => {
			  if (response.status === 200) {
				this.onAdd();
			  }
			})
			.catch((error) => {
			  if (error.response.status === 400) {
				this.details.status = !this.details.status;
				this.text = 'The subscriber does not have enough funds';
				this.snackbar = true;
			  } else {
				this.details.status = !this.details.status;
				this.text = 'Connection error';
				this.snackbar = true;
			  }
			});
		},
		addPackage(pk) {
		  this.loadingAviablePackages = true;
		  axios
			.put(`/api/cards/edit/package/${this.$route.params.id}/`, {
			  package: pk
			})
			.then((response) => {
			  if (response.status === 200) {
				this.$store.dispatch('setBalance', response.data.balance);
				this.onAdd();
			  }
			})
			.catch((error) => {
			  if (error.response.status === 400) {
				this.details.status = !this.details.status;
				this.text = 'The subscriber does not have enough funds';
				this.snackbar = true;
			  } else {
				this.details.status = !this.details.status;
				this.text = 'Connection error';
				this.snackbar = true;
			  }
			});
		},
	},
}
</script>
