<template>
	<div fluid>
	  <v-card class="mb-3">
		<v-card-title>
		  <v-text-field
			v-model="search"
			append-icon="search"
			:label="t('Search')"
			single-line
			hide-details
		  ></v-text-field>
		</v-card-title>
	  </v-card>
	  <v-card class="my-3">
		<v-subheader>{{ t('Packages') }}</v-subheader>
		<v-data-table
		  :headers="headers"
		  :items="packages"
		  items-per-page="5"
		  class="elevation-1"
		  :loading="loadingPackages"
		  :search="search"
		  :rows-per-page-text="t('Rows per page')"
		  :rows-per-page-items="[
			10,
			25,
			50,
			{ text: $translate.locale['All'], value: -1 }
		  ]"
		>
		  <template slot="items" slot-scope="props">
			<td class="text-xs-left no-wrap">
			  {{ props.item.header }}
			  <v-tooltip bottom v-if="props.item.delayed">
				<template v-slot:activator="{ on }">
				  <v-icon small v-on="on" color="warning">add_alarm</v-icon>
				</template>
				<span
				  >{{
					t(
					  'This package will be removed on the next transaction.')
				  }}
				</span>
			  </v-tooltip>
			</td>
			<td class="text-xs-left no-wrap">
			  {{
				$moment(props.item.expired_date).format(
				  'YYYY-MM-DD HH:mm:ss'
				)
			  }}
			</td>
			<!-- <td class="text-xs-left no-wrap">{{ props.item.tariff + $store.getters.currency}}</td> -->
			<td class="text-xs-left no-wrap">
			  <v-tooltip bottom>
				<template v-slot:activator="{ on }">
				  <v-btn
						v-on="on"
						:to="`/packages/${props.item.pk}/details/`"
						color="info"
						ripple
						icon
						small
						dark
				  >
					<v-icon small>info</v-icon>
				  </v-btn>
				</template>
				<span>{{ t('Detail') }}</span>
			  </v-tooltip>
			  <v-tooltip bottom v-if="!isManager">
					<template v-slot:activator="{ on }">
				  	<v-btn
							v-on="on"
							color="error"
							@click="removeCard(props.item.pk)"
							ripple
							icon
							small
							dark
				  	>
							<v-icon small>close</v-icon>
				  	</v-btn>
					</template>
					<span>{{ t('Remove now') }}</span>
			  </v-tooltip>
			  <v-tooltip bottom v-if="!props.item.delayed && !isManager">
					<template v-slot:activator="{ on }">
				  	<v-btn
						v-on="on"
						color="error darken-1"
						@click="removeCardLater(props.item.pk)"
						ripple
						icon
						small
						dark
				  	>
						<v-icon small>alarm_off</v-icon>
				  	</v-btn>
					</template>
					<span>{{ t('Remove later') }}</span>
			  </v-tooltip>
			</td>
		  </template>
		  <template slot="pageText" slot-scope="item">
			{{ t('Elements') }} {{ item.pageStart }} - {{ item.pageStop }},
			{{ t('total') }}: {{ item.itemsLength }}
		  </template>
		  <template slot="no-data">
			<v-subheader>{{ t('No data available') }}</v-subheader>
		  </template>
		</v-data-table>
	  </v-card>
	</div>
</template>
<script>
import axios from 'axios';
import {mapGetters} from 'vuex';

export default {
	name: 'Packages',
	props: ['packages', 'loadingPackages', 'onRemove'],
	data() {
		return {
			search: '',
		    headers: [
				{
				  text: this.$translate.locale['Header'],
				  align: 'left',
				  value: 'header'
				},
				{
				  text: this.$translate.locale['Expired date'],
				  value: 'expired_date'
				},
				{
				  text: this.$translate.locale['Action'],
				  value: 'action',
				  sortable: false
				}
		    ],
		}
	},
	methods: {
		removeCardLater(pk) {
		  axios
			.post(`/api/cards/delayed-remove/package/${this.$route.params.id}/`, {
			  package: pk
			})
			.then((response) => {
			  if (response.status === 200) {
				this.onRemove();
			  }
			})
			.catch((error) => {
			  this.text = 'Connection error';
			  this.snackbar = true;
			});
		},
		removeCard(pk) {
		  axios
			.put(`/api/cards/delete/package/${this.$route.params.id}/`, {
			  package: pk
			})
			.then((response) => {
			  if (response.status === 200) {
				this.onRemove();
			  }
			})
			.catch((error) => {
			  this.text = 'Connection error';
			  this.snackbar = true;
			});
		},
	},
	computed: {
		...mapGetters(['isManager'])
	}
}	
</script>
