<template>
  <div class="generate-pdf-file">
    <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            top
    >
        {{ t(text) }}
        <v-btn
                flat
                @click="snackbar = false"
        >
            {{t('Close')}}
        </v-btn>
    </v-snackbar>
    <v-btn
      color="accent"
      dark
      ripple
      icon
      class="ml-auto ma-0"
      @click="generateReport"
    >
      <v-icon>print</v-icon>
    </v-btn>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="$vuetify.breakpoint.smAndUp"
      :paginate-elements-by-height="1400"
      :filename="filename"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
      id="html2Pdf"
    >
      <section slot="pdf-content">
        <section class="pdf-item">
          <v-card class="mx-auto" relative>
            <img 
              src="/company_logo_report.png" 
              alt="logo" 
              class="company_logo"
            >
            <v-list two-line subheader dense>
              <v-subheader>
                {{ t('Details') }}
              </v-subheader>
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-icon class="black lighten-1 white--text"
                    >folder_special</v-icon
                  >
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title class="bigger-text">{{ t('Label') }}</v-list-tile-title>
                  <v-list-tile-sub-title
                      class="bigger-text"
                    >
                    {{ cardLabel }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-icon class="black lighten-1 white--text">
                    insert_invitation
                  </v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title class="bigger-text">{{ t('Created at') }}</v-list-tile-title>
                  <v-list-tile-sub-title class="bigger-text">
                    {{ formatDate(createdAt) }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile avatar>
                <v-list-tile-avatar>
                  <v-icon class="black lighten-1 white--text">person</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title class="bigger-text">{{ t('Subscriber') }}</v-list-tile-title>
                  <v-list-tile-sub-title class="bigger-text">
                    {{ subscriberName }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-card>
        </section>
        <section class="pdf-item">
          <v-card class="mt-3">
            <v-subheader class="bigger-text">{{ t('Packages') }}</v-subheader>
            <table class="v-datatable v-table theme--light">
              <thead>
                <tr>
                  <th class="text-xs-left bigger-text">Name</th>
                  <th class="text-xs-left bigger-text">Old date</th>
                  <th class="text-xs-left bigger-text">New date</th>
                  <th class="text-xs-left bigger-text">Duration</th>
                  <th class="text-xs-left bigger-text">Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-xs-left bigger-text">{{ packetHeader }}</td>
                  <td class="text-xs-left bigger-text"> {{ formatDate(oldExpiredDate) }} </td>
                  <td class="text-xs-left bigger-text"> {{ formatDate(newExpiredDate) }} </td>
                  <td class="text-xs-left bigger-text"> {{ duration }} </td>
                  <td class="text-xs-left bigger-text"> {{ price }} </td>
                </tr>
              </tbody>
            </table>
          </v-card>
        </section>
        <section class="pdf-item">
          <v-card class="mt-3">
            <v-subheader>
              {{ t('Date of printing') + ': ' }}
              <b>
                {{
                  $moment().format('YYYY-MM-DD HH:mm:ss')
                }}
              </b>
            </v-subheader>
          </v-card>
        </section>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import axios from 'axios';

export default {
  name: 'CardPrintPDF',
  data() {
    return {
      cardLabel: '',
      price: 0,
      oldExpiredDate: '',
      newExpiredDate: '',
      duration: '',
      subscriberName: '',
      packetHeader: '',
      createdAt: '',

      timeout: 5000,
      snackbar: '',
      text: '',
    }
  },
  computed: {
    filename() {
      return this.$moment().format('YYYY-MM-DD HH:mm:ss');
    },
  },
  methods: {
    formatDate(date) {
      return this.$moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    generateReport() {
      this.getLastCardReport().then( () => {
        this.$refs.html2Pdf.generatePdf();
      });
    },
    getLastCardReport() {
      return axios.get(`/api/cards/${this.$route.params.id}/report`)
        .then((response) => {
          let data = response.data;
          this.createdAt = data.created_at;
          this.cardLabel = data.card_label;
          this.price = data.price;
          this.oldExpiredDate = data.old_expired_date;
          this.newExpiredDate = data.new_expired_date;
          this.duration = data.duration;
          this.subscriberName = data.subscriber_name;
          this.packetHeader = data.packet_header;
        }).catch((error) => {
          if (error.response.status === 404) {
            this.text = error.response.data.error_message;
            this.snackbar = true;
            throw new Error();
          }
        });
    }
  },
  components: {
    VueHtml2pdf
  }
};
</script>

<style lang="scss">

.generate-pdf-file {
  margin-left: auto;
}
.bigger-text {
  font-size: 15px !important;
  color: black !important;
  font-weight: bold !important;
}
.company_logo {
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(calc(-100% - 5px), 5px);
  max-height: 200px;
  max-width: 200px;
}


</style>
