<template>
  <v-content color="primary">
    <v-container fluid id="main-container" grid-list-md fill-height>
      <v-snackbar v-model="snackbar" :timeout="timeout" top>
        {{ t(text) }}
        <v-btn flat @click="snackbar = false">
          {{ t('Close') }}
        </v-btn>
      </v-snackbar>
      <v-layout justify-center wrap>
        <v-flex sm8 xs12>
          <v-card class="mx-auto" :loading="loading">
            <v-list two-line subheader dense>
              <v-subheader>
                {{ t('Details') }}
                <v-dialog
                  v-if="this.details.status !== 'Active' && !isManager"
                  v-model="deleteDialog"
                  max-width="290"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="error"
                      v-on="on"
                      small
                      icon
                      ripple
                      class="ml-auto mr-0"
                    >
                      <v-icon small>delete</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">{{
                      t('Are you sure you want to delete?')
                    }}</v-card-title>
                    <v-card-text
                      >{{ t('This action cannot be undone') }}
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        small
                        color="error"
                        @click="deleteDialog = false"
                        >{{ t('Close') }}</v-btn
                      >
                      <v-btn
                        outline
                        small
                        color="error"
                        @click="deleteCard"
                        >{{ t('Delete') }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-list-tile-avatar>
                  <v-tooltip bottom>
                    <v-btn
                      color="green"
                      dark
                      small
                      ripple
                      icon
                      class="ml-auto"
                      @click="synchronizeCard"
                    >
                    </v-btn>
                    <span>{{ t('Synchronize Card') }}</span>
                  </v-tooltip>
                </v-list-tile-avatar>
              </v-subheader>
              <v-subheader>
                <!-- print -->
                <CardPrintPDF
                  v-if="details"
                  ref="cardPrinter"
                />
                <!-- print -->
              </v-subheader>
              <v-list-tile avatar @click="copyCardLabel">
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text"
                    >folder_special</v-icon
                  >
                </v-list-tile-avatar>
                <v-list-tile-content ref="CardLabelContainer">
                  <v-list-tile-title>{{ t('Label') }}</v-list-tile-title>
                  <v-list-tile-sub-title>
					{{ details.label }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <div
                  :class="{
                    '': $vuetify.breakpoint.smAndUp
                  }"
                  class="d-flex align-center justify-center"
                >
                  <v-btn
                    flat
                    color="primary"
                    @click.stop="synchronizeCard"
                    class="ma-0 pa-0"
                    style="min-width: 0;"
                  >
                  <span
                    :class="{
                      'd-none': $vuetify.breakpoint.xs,
                    }"
                  >
                    {{ t('Send to scrambler') }}
                  </span>  
                    <v-icon large>autorenew</v-icon>
                  </v-btn>
                </div>
              </v-list-tile>
              <v-list-tile avatar @click="">
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text"
                    >playlist_add_check</v-icon
                  >
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ t('Status') }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{
                    t(details.status)
                  }}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-avatar v-if="!isManager">
                  <v-switch
                    v-model="status"
                    @change="updateStatus"
                    :disabled="
                      details.status !== 'Active' &&
                      details.status !== 'Suspend'
                    "
                  ></v-switch>
                </v-list-tile-avatar>
              </v-list-tile>
              <v-list-tile avatar @click="">
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text"
                    >account_balance_wallet</v-icon
                  >
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ t('Price') }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{
                    totalPrice
                  }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile avatar @click="">
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">event_busy</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ t('Expired date') }}</v-list-tile-title>
                  <v-list-tile-sub-title
                    >{{
                      $moment(details.expired_date).format(
                        'YYYY-MM-DD HH:mm:ss'
                      )
                    }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <div
                  :class="{
                    'd-none': $vuetify.breakpoint.xs,
                    '': $vuetify.breakpoint.smAndUp
                  }"
                  class="d-flex align-center justify-center"
                  v-if="
                    $store.getters.isPREPAYMENT && details.status !== 'Inactive'
                  "
                >
                  <v-form ref="form">
                    <!-- <v-text-field
                            :label="t('Periods')"
                            class="mr-2 ml-auto"
                            type="number"
                            :rules="[rules.counter, rules.number]"
                            v-model="numOfPeriods"
                    ></v-text-field> -->
                    <v-flex mt-4 style="width: 250px">
                      <v-select
                        :items="periods"
                        :label="t('Payment periods')"
                        solo
                        v-model="choosenPeriod"
                      >
                        <template slot="selection" slot-scope="{ item }">
                          {{ t(item.name) }} ({{ t(item.package_name) }})
                        </template>
                        <template slot="item" slot-scope="{ item }">
                          {{ t(item.name) }} ({{ t(item.package_name) }})
                        </template>
                      </v-select>
                    </v-flex>
                  </v-form>
                  <!-- new expired date dialog -->
                  <v-dialog
                    v-model="cardDetailsDialog"
                    persistent
                    max-width="600"
                    v-if="$vuetify.breakpoint.smAndUp"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="pink"
                        dark
                        small
                        ripple
                        icon
                        class="ml-auto"
                        v-bind="attrs"
                        v-on="on"
                        v-show="typeof choosenPeriod === 'object'"
                      >
                        <v-icon small>autorenew</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="primary">
                        <h2 class="white--text">
                          {{
                            t(
                              'You are updating subscription date for this card'
                            )
                          }}
                        </h2>
                      </v-card-title>
                      <v-card-text>
                        <p class="card-details-dialog__text">
                          <b>{{ currentExpiredDate }}</b> &#8211;
                          <b>{{ newExpiredDate }}</b>
                        </p>
                        <h2>
                          {{ t('Do you agree') }}
                        </h2>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="cardDetailsDialog = false">
                          {{ t('Button No') }}
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click="
                            cardDetailsDialog = false;
                            updateCardPeriod();
                          "
                        >
                          {{ t('Button Yes') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <!-- new expired date dialog -->
                </div>
              </v-list-tile>
              <v-list-tile
                avatar
                :class="{
                  '': $vuetify.breakpoint.xs,
                  'd-none': $vuetify.breakpoint.smAndUp
                }"
                v-if="
                  $store.getters.isPREPAYMENT && details.status !== 'Inactive'
                "
              >
                <div class="d-flex align-center justify-center">
                  <v-form ref="form">
                    <!-- <v-text-field
                            :label="t('Periods')"
                            class="mr-2 ml-auto"
                            type="number"
                            :rules="[rules.counter, rules.number]"
                            v-model="numOfPeriods"
                    ></v-text-field> -->

                    <v-select
                      :items="periods"
                      :label="t('Payment Period')"
                      solo
                      v-model="choosenPeriod"
                    >
                      <template slot="selection" slot-scope="{ item }">
                        {{ t(item.name) }} ({{ t(item.package_name) }})
                      </template>
                      <template slot="item" slot-scope="{ item }">
                        {{ t(item.name) }} ({{ t(item.package_name) }})
                      </template>
                    </v-select>
                  </v-form>
                  <!-- new expired date dialog -->
                  <v-dialog
                    v-model="cardDetailsDialog"
                    persistent
                    max-width="600"
                    v-if="$vuetify.breakpoint.xs"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="pink"
                        dark
                        small
                        ripple
                        icon
                        class="mr-auto mb-auto"
                        v-bind="attrs"
                        v-on="on"
                        v-show="typeof choosenPeriod === 'object'"
                      >
                        <v-icon small>autorenew</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="primary">
                        <h2 class="white--text">
                          {{
                            t(
                              'You are updating subscription date for this card'
                            )
                          }}
                        </h2>
                      </v-card-title>
                      <v-card-text>
                        <p class="card-details-dialog__text">
                          <b>{{ currentExpiredDate }}</b> &#8211;
                          <b>{{ newExpiredDate }}</b>
                        </p>
                        <h2>
                          {{ t('Do you agree') }}
                        </h2>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="cardDetailsDialog = false">
                          {{ t('Button No') }}
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click="
                            cardDetailsDialog = false;
                            updateCardPeriod();
                          "
                        >
                          {{ t('Button Yes') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <!-- new expired date dialog -->
                </div>
              </v-list-tile>
              <v-list-tile avatar @click="">
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">event_note</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ t('Last change') }}</v-list-tile-title>
                  <v-list-tile-sub-title
                    >{{
                      $moment(details.last_change).format('YYYY-MM-DD HH:mm:ss')
                    }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile avatar @click="">
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text"
                    >insert_invitation</v-icon
                  >
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ t('Created at') }}</v-list-tile-title>
                  <v-list-tile-sub-title
                    >{{
                      $moment(details.created_at).format('YYYY-MM-DD HH:mm:ss')
                    }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile 
                v-if="details.comment !== undefined" 
                avatar
                class="comment-tile"
                @click=""
              >
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">comment</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ t('Comment') }}</v-list-tile-title>
                  <v-list-tile-sub-title>
                    <vue-editor 
                      v-if="!commentEditor.config.disabled"
                      v-model="details.comment"
                      style="width: 100%"
                      class="comment-input"
                      :editorToolbar="commentEditor.toolbar"
                      :disabled="commentEditor.config.disabled"
                      readonly
                    ></vue-editor>
                    <div
                      v-if="commentEditor.config.disabled"
                      v-html="details.comment"
                      class="preview-comment"
                    ></div>
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-btn
                  v-if="commentEditor.config.disabled"
                  @click="allowCommentEdit(true)"
                  color="success"
                  icon
                  ripple
                  small
                  dark
                >
                  <v-icon small>edit</v-icon>
                </v-btn>
                <v-btn
                  v-if="!commentEditor.config.disabled"
                  @click="updateComment();allowCommentEdit(false)"
                  color="success"
                  icon
                  ripple
                  small
                  dark
                >
                  <v-icon small>check</v-icon>
                </v-btn>
              </v-list-tile>
              <v-list-tile avatar @click="" v-if="details.subscriber">
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">person</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ t('Subscriber') }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{
                    details.subscriber.first_name
                  }}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-avatar>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        :to="`/subscribers/${details.subscriber.pk}/edit/`"
                        color="success"
                        icon
                        ripple
                        small
                        dark
                      >
                        <v-icon small>edit</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('Edit') }}</span>
                  </v-tooltip>
                </v-list-tile-avatar>
                <v-list-tile-avatar>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        :to="`/subscribers/${details.subscriber.pk}/details/`"
                        color="info"
                        ripple
                        icon
                        small
                        dark
                      >
                        <v-icon small>info</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('Detail') }}</span>
                  </v-tooltip>
                </v-list-tile-avatar>
              </v-list-tile>
              <v-list-tile avatar @click="">
                <v-list-tile-avatar>
                  <v-icon class="grey lighten-1 white--text">people</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ t('Reseller') }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{
                    details.reseller.email
                  }}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-avatar>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        :to="`/resellers/${details.reseller.pk}/details/`"
                        color="info"
                        ripple
                        icon
                        small
                        dark
                      >
                        <v-icon small>info</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ t('Detail') }}</span>
                  </v-tooltip>
                </v-list-tile-avatar>
              </v-list-tile>
            </v-list>
          </v-card>
		  <logs :logs="details.logs"/>
        </v-flex>
		<packages-and-available-packages
			:details="details"
			:loadingPackages="loadingPackages"
			:onAdd="getData"
			:onRemove="getData"
			ref="packages_and_available_packages"
		/>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import CardPrintPDF from './CardPrintPDF.vue';
import { VueEditor } from "vue2-editor";
import PackagesAndAvailablePackages from '../../components/Packages/PackagesAndAvailablePackages.vue'
import Logs from '../../components/Logs/Logs.vue'

export default {
  components: { CardPrintPDF, VueEditor, PackagesAndAvailablePackages, Logs },
  name: 'CardsDetails',
  data() {
    return {
	  packages: [],
	  loadingPackages: true,
      snack: false,
      snackColor: '',
      snackText: '',
      loading: true,
      snackbar: false,
      dialog: false,
      searchLogs: '',
      text: 'Oops... Something went wrong',
      timeout: 5000,
      newBalance: '',
      numOfPeriods: 1,
      commentEdit: false,
      commentEditor:{
        toolbar: [
          [{color: []}, {background: []},
          {header: [false, 1, 2, 3, 4, 5, 6]},
          "bold", "italic", "underline", "strike"]
        ],
        config: {
          disabled: true
        }
      },
      details: {
		    logs: [],
        address: '',
        balance: 0,
        cards: Array(0),
        email: '',
        first_name: '',
        id: 0,
        last_name: '',
        reseller: 0,
        telephone: '',
        comment: undefined,
    },
	  logsOptions: {
		  sortBy: 'date_timestamp',
		  descending: true,
	  },
      headersLogs: [
        { text: this.$translate.locale['Date'], value: 'date_timestamp' },
        {
          text: this.$translate.locale['Header'],
          align: 'left',
          //  sortable: false,
          value: 'log'
        }
      ],
      rules: {
        counter: (value) =>
          (value <= 2147483647 && value >= 1) ||
          this.$translate.locale['Min 1 and Max 2147483647'],
        number: (value) => {
          const pattern = /^\d+$/;
          return (
            pattern.test(value) || this.$translate.locale['Invalid number.']
          );
        }
      },
      deleteDialog: false,
      periods: [],
      choosenPeriod: '',
      cardDetailsDialog: false
    };
  },
  computed: {
    choosenPackage() {
      if (typeof this.choosenPeriod === 'string') {
        return '';
      }

      const choosenPackage = this.packages.find(
        (item) => item.header === this.choosenPeriod.package_name
      );

      return choosenPackage;
    },
    currentExpiredDate() {
      const expiredDate = this.$moment(
        this.choosenPackage.expired_date,
        'YYYY-MM-DD HH:mm:ss'
      );
      const nowDate = this.$moment();

      return expiredDate.diff(nowDate) > 0
        ? expiredDate.format('YYYY-MM-DD HH:mm:ss')
        : nowDate.format('YYYY-MM-DD HH:mm:ss');
    },
    newExpiredDate() {
      // DAYS
      // WEEKS
      // MONTHS

      if (typeof this.choosenPeriod === 'string') {
        return '';
      }

      const choosenTariff = this.choosenPackage.tariffs.find(
        (item) => item.name === this.choosenPeriod.name
      );

      return this.$moment(this.currentExpiredDate, 'YYYY-MM-DD HH:mm:ss')
        .add(choosenTariff.quantity, choosenTariff.kind_period.toLowerCase())
        .format('YYYY-MM-DD HH:mm:ss');
    },
    status() {
      return this.details.status === 'Active';
    },
    totalPrice() {
      let sum = this.details.price;
      if (this.choosenPeriod.tariff) {
        sum = parseFloat(sum) + parseFloat(this.choosenPeriod.tariff);
      }
      return `${sum}${this.$store.getters.currency}`;
    },
    ...mapGetters(['isManager'])
  },
  methods: {
	  copyCardLabel() {
		  console.log('copy card label')
		  let CardLabelContainer = this.$refs.CardLabelContainer;
		  let cardLabel = document.createElement('textarea');
		  cardLabel.value = this.details.label.split('-').slice(-1);
		  CardLabelContainer.appendChild(cardLabel);
		  cardLabel.focus();
		  cardLabel.select();
		  document.execCommand('copy');
		  CardLabelContainer.removeChild(cardLabel);
		  this.text = 'Copied card label';
		  this.snackbar = true;
	  },
    getData() {
      this.loadingPackages = true;
	    this.choosenPeriod = '';
      axios
        .get(`/api/cards/${this.$route.params.id}/`)
        .then((response) => {
          if (response.status === 200) {
            this.details = response.data;
            this.packages = this.details.packages;
            this.parsePeriods();
            this.newBalance = this.details.balance;
            this.loadingPackages = false;
			      this.$refs.packages_and_available_packages.updateAvailablePackages();
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.text = 'This card does not exist'
          }
          else {
			      this.text = 'Connection error';
          }
			    console.log(error)
			    this.snackbar = true;
        });
    },
    parsePackages() {
      this.packages = [];
      for (const i in this.details['packages']) {
        let period = [];
        for (const j in this.details['packages'][i]['packet_cards']) {
          if (
            this.details['packages'][i]['packet_cards'][j].card ===
            parseInt(this.$route.params.id)
          ) {
            period = this.details['packages'][i]['packet_cards'][j];
            this.packages[i] = this.details['packages'][i];
            this.packages[i]['packet_cards'] = period;
            break;
          }
        }
      }
    },
    parsePeriods() {
      this.periods = [];
      for (const i in this.packages) {
        for (const j in this.packages[i]['tariffs']) {
          this.periods.push({
            id: this.packages[i]['tariffs'][j]['pk'],
            name: this.packages[i]['tariffs'][j]['name'],
            kind_period: this.packages[i]['tariffs'][j]['kind_period'],
            tariff: this.packages[i]['tariffs'][j]['tariff'],
            quantity: this.packages[i]['tariffs'][j]['quantity'],
            package_id: this.packages[i]['pk'],
            package_name: this.packages[i]['header']
          });
        }
      }
    },
    updateStatus() {
      axios
        .put(`/api/cards/edit/status/${this.$route.params.id}/`)
        .then((response) => {
          if (response.status === 200) {
            this.getData();
            this.text = 'Status changed!';
            this.snackbar = true;
          }
        })
        .catch((error) => {
          this.details.status = !this.details.status;
          this.text = 'Connection error';
          this.snackbar = true;
        });
    },
    updateCardPeriod() {
      if (!this.$refs.form.validate()) {
        this.text = 'Fill the form correctly';
        this.snackbar = true;
      } else {
        this.loading = true;
        axios
          .put(`/api/cards/edit/expired/${this.$route.params.id}/`, {
            period: this.choosenPeriod
          })
          .then((response) => {
            if (response.status === 200) {
              this.getData();
              this.$store.dispatch('setBalance', response.data.balance);
              this.text = 'Period changed!';
              this.snackbar = true;
            }
          })
          .catch((error) => {
            this.text = error.response.data.errors || error.response.data.detail;
            this.snackbar = true;
          });
      }
    },
    deleteCard() {
      axios
        .delete(`/api/cards/delete/${this.$route.params.id}/`)
        .then((response) => {
          if (response.status === 204) {
            this.$router.push('/cards');
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            console.log(error.response.data)
            this.text = this.$translate.locale[error.response.data.errors || 'Cannot delete this card'];
            this.snackbar = true;
          } else {
            this.text = 'Connection error';
            this.snackbar = true;
          }
        });
    },
    synchronizeCard() {
      axios
        .post(`/api/cards/${this.$route.params.id}/sync/`)
        .then((response) => {
          if (response.status === 200) {
		    this.snackbar = true;
            this.text = 'Successfully synchronized Card';
          }
        })
        .catch((error) => {
		  this.snackbar = true;
          this.text = 'Internal server error';
        });
    },
    sendToScrambler() {},
    allowCommentEdit(yes) {
      this.commentEditor.config.disabled = !yes;
    },
    updateComment() {
      axios
        .put(`/api/cards/edit/comment/${this.$route.params.id}/`, {
          comment: this.details.comment
        })
        .then((response) => {
          if (response.data.success) {
            this.text = 'Updated card comment';
            this.snackbar = true;
          }
        })
        .catch((error) => {
          this.text = 'Error while updating comment';
          this.snackbar = true;
        });
    }
  },
  beforeCreate() {
    if (!this.$store.getters.loggedIn) {
      this.$router.push('/');
    }
  },
  mounted() {
    this.getData();
  }
};
</script>

<style lang="scss">
.card-details-dialog__text {
  font-size: 18px;
}
.v-list--two-line.v-list--dense .comment-tile .v-list__tile{
  height: auto;
}
.ql-editor {
  font-size: 13px !important;
  color: rgba(0,0,0,0.54) !important;
}
.preview-comment * {
  padding: 0;
  margin: 0;
}
</style>
